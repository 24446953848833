@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_sizes.scss';

#about-page-container {
    color: $theme-primary-color;
    padding-top: $default-page-container-padding-top;
    padding-bottom: 70px;
}

#who-we-are-header {
    margin-top: 55px;
}
