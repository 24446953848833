@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_sizes.scss';

#donate-page-container {
    color: $theme-primary-color;
    padding-top: $default-page-container-padding-top;
    padding-bottom: 70px;
}

#support-surfy-patreon-btn {
    color: $background-color;
    background-color: $theme-primary-color;
    padding: 14px 30px;
}

#support-surfy-patreon-btn:hover {
    color: $theme-primary-color;
    background-color: $background-color;
    border: 1px solid $theme-primary-color;
}

