.clickable-image, .clickable-image-caption {
    cursor: pointer;
}

.clickable-image:hover {
    // box-shadow: 0 0.5rem 1rem rgba(27, 121, 214, 0.15) !important;
}

.clickable-image-modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: none;
}

.clickable-image-modal-body {
    padding: 0;
}

.clickable-image-modal-body > img {
    width: 100%;
}
