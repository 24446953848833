@import "./assets/styles/_colors.scss";
@import "./assets/styles/_sizes.scss";

.App {
  padding-top: 56px;
}

.page-title {
  font-size: 28px;
  color: $theme-primary-color-dark;
}

.page-description {
  font-size: 26px;
  color: $theme-primary-color;
}

.inline-link {
  text-decoration: underline;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  color: $theme-primary-color-dark;
}

@media (min-width: 576px) {
  .App {
    padding-top: $navbar-height;
  }

  .page-title {
    font-size: 38px;
  }
  .page-description {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
    .page-title {
      font-size: 44px;
    }
    .page-description {
      font-size: 32px;
    }
}

@media (min-width: 992px) {
    .page-title {
      font-size: 50px;
    }
}
