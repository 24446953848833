@import '../assets/styles/_colors.scss';
@import '../assets/styles/_sizes.scss';

#how-it-works-title {
    font-size: 36px;
}

#how-it-works-container {
    background-color: $theme-primary-color;
    color: $background-color;
    margin-top: 45px;
    padding-top: 65px;
    padding-bottom: 110px;
}

.how-it-works-icon {
    font-size: 112px;
}

.how-it-works-icon, .how-it-works-arrow {
    margin-top: 50px;
}

.how-it-works-description {
    margin-top: 22px;
    font-size: 16px;
}

#media-bias-fact-check-description > a, #media-bias-fact-check-description > a:hover {
    color: $background-color;
}

.how-it-works-arrow {
    font-size: 60px;
}

#you-browse-description {
    white-space: nowrap;
    overflow: visible;
}

@media (min-width: 576px) {
    #how-it-works-container {
        margin-top: 65px;
    }
    #how-it-works-title {
        font-size: 54px;
    }
    .how-it-works-icon, .how-it-works-arrow {
        margin-top: 60px;
    }
}

@media (min-width: 768px) {
    #how-it-works-container {
        margin-top: 75px;
    }
    #how-it-works-title {
        margin-bottom: 60px;
    }
    .how-it-works-icon, .how-it-works-arrow {
        margin-top: 0px;
    }
}

@media (min-width: 992px) {
    #how-it-works-container {
        margin-top: 105px;
    }
}
