@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_sizes.scss';

#landing-page-container {
    padding-top: $default-page-container-padding-top;
}

#intro-text-small {
    font-size: 19px;
}

#intro-text-large {
    font-size: 24px;
}

#summary-text-row {
    margin-top: 30px;
    font-size: 20px;
    color: $theme-primary-color-light;
}

#intro-text-small {
    color: $theme-primary-color-light;
}

#intro-text-large {
    color: $theme-primary-color-dark;
}

@media (min-width: 576px) {
    #intro-text-small {
        font-size: 24px;
    }
    #intro-text-large {
        font-size: 38px;
    }
    #summary-text-row {
        margin-top: 35px;
        font-size: 28px;
    }
}

@media (min-width: 768px) {
    #intro-text-small {
        font-size: 30px;
    }
    #intro-text-large {
        font-size: 52px;
    }
    #summary-text-row {
        margin-top: 45px;
        font-size: 28px;
    }
}

@media (min-width: 992px) {
    #intro-text-small {
        font-size: 32px;
    }
    #intro-text-large {
        font-size: 54px;
    }
    #example-photo-row {
        margin-top: 15px;
    }
    #summary-text-row {
        margin-top: 65px;
        font-size: 30px;
    }
}
