@import '../assets/styles/_colors.scss';

#privacy-policy-title {
    font-size: 36px;
}

#privacy-policy-container {
    color: $theme-primary-color;
    padding-top: 80px;
    padding-bottom: 120px;
}


@media (min-width: 576px) {
    #privacy-policy-title {
        font-size: 54px;
    }
}