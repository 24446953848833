@import '../assets/styles/_colors.scss';



.teammate-bio-name {
    color: $theme-primary-color-dark;
    font-size: 1.5rem;
}

.teammate-bio-position {
    color: $theme-primary-color;
    font-size: 1.1rem;
}
