@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_sizes.scss';

#under-construction-page-container {
    color: $theme-primary-color;
    padding-top: $default-page-container-padding-top + 20px;
    padding-bottom: 50px;
}

.huge-icon {
    font-size: 10rem;
}
