@import '../assets/styles/_colors.scss';
@import '../assets/styles/_sizes.scss';

// I got these values from the browser extension (from content.scss)
.color-code-very-good {
    background-color: #39c113;
}
.color-code-good {
    background-color: #7ece1c;
}
.color-code-mixed {
    background-color: #7ece1c;
    background: repeating-linear-gradient(-45deg, #ffee59, #ffee59 5px, #7ece1c 5px, #7ece1c 20px);
}
.color-code-poor {
    background-color: #fc6450;
}
.color-code-satire {
    background-color: #59a6ff;
}
.color-code-fake {
    background: repeating-linear-gradient(-45deg, #ffee59, #ffee59 5px, #cc0202 5px, #cc0202 20px);
}
.color-code-conspiracy {
    background: repeating-linear-gradient(-45deg, #c268ff, #c268ff 5px, #cc0202 5px, #cc0202 20px);
}

.surfy-badge {
    background-color: #0638ce;
    margin-top: 4px;
    padding-top: 1px;
    border-radius: 2px;
    height: 22px;
    font-size: 16px;
    line-height: 20px;
    color: white;
    width: 67px;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.color-code-color {
    width: 100%;
    height: 30px;
}

.color-code-equals {
    font-size: 1rem;
}

.color-code-meaning {
    padding-top: 2px;
}
