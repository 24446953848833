@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_sizes.scss';

@font-face {
  font-family: 'Livvic';
  src: url('../../assets/fonts/livvic/Livvic-Regular.ttf') format('truetype');
}

#surfy-navbar {
    background-color: $background-color;
    padding-left: 8%;
    padding-right: 8%;
}

@media (min-width: 576px) {
    #surfy-navbar {
        min-height: $navbar-height;
    }
}

#navbar-logo {
    vertical-align: baseline;
}

#navbar-title {
    line-height: 40px;
    color: $theme-primary-color;
    font-size: 50px;
    font-family: 'Livvic';
}

#navbar-donate-btn > a, #navbar-about-btn > a {
    color: $theme-primary-color;
    line-height: 26px;
}

#navbar-get-surfy-btn {
    background-color: $theme-primary-color;
    border: 1px solid $theme-primary-color;
    border-radius: 3px;
    padding: 8px 25px;
    color: $background-color;
}

#navbar-get-surfy-btn:hover {
    background-color: $background-color;
    color: $theme-primary-color;
}

#navbar-get-surfy-btn > a {
    color: $background-color;
}
